.unit-description-v1 {
	.description {
		margin-bottom: 30px;
	}

	.show-more {
		font-size: var(--font-size-md);
		padding: 0;
		border: none;
		color: var(--color-primary);

		&:hover {
			background-color: #fff;
			color: var(--color-primary);
			text-decoration: underline;
		}

		&:focus {
			background-color: #fff;
			color: var(--color-primary);
			text-decoration: underline;
		}
	}
}
.themes-v1 {
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;

	.theme-cols {
		padding-right: 2px;
		padding-left: 2px;
		padding-top: 2px;

		@media(min-width:993px) {
			width: 20%;

			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}


		.theme-card {
			position: relative;

			.theme-cont-bl {
				background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
				height: 64px;
				position: absolute;
				top: auto;
				bottom: 0 !important;
				left: 0;
				padding: 1.25rem;
				width: 100%;

				h4 {
					position: absolute;
					bottom: 10px;
					padding-right: 20px;
					margin-bottom: 0.75rem;
					color: #fff;
				}
			}

			.theme-img-cont {
				position: relative;
				overflow: hidden;

			}

			&:hover {
				img {
					transform: scale(1.2);
				}
			}

			img {
				transition: 0.6s ease;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none;

	}

	.theme-card {
		background-size: contain;
		display: block;
		background-repeat: no-repeat;

		img {
			transition: all .2s ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.2);
			}
		}
	}

	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/kamin.webp);
	}


	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/sauna.webp);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/hund.webp);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/familie.webp);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/familie.webp);
	}



	/*[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
		padding: 10px;

		@media (max-width: 767px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		border-radius: var(--border-radius);
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);

	}



	@media (min-width: 767px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;

		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;

		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 50px;
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
			font-size: 20px;
			font-weight: 400;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 766px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}*/
}
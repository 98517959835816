.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}


	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.map-bl {
		.orange {
			color: var(--color-secondary);
			font-weight: 600;
		}

		.multicolumn {
			column-count: 3;
			width: 100%;
			padding-left: 0;

			@media(max-width:767px) {
				font-size: 12px;
			}

			li {
				width: 100%;
				display: inline-block;
				border-bottom: 1px solid #E5E5E5;
				padding: 4px 0px 4px 0px;

				a {
					color: #202429;
					text-decoration: none;

					.fa-chevron-right {
						color: var(--color-primary);
						font-weight: bold;
						font-size: 14px;
						margin-right: 5px;

						@media(max-width:767px) {
							font-size: 10px;
						}
					}
				}
			}
		}

		.imgmap {
			position: relative;
			overflow: hidden;
		}

		.imgmap a {
			position: absolute;
			width: 18px;
			height: 18px;
		}

		a#ma24 {
			left: 73.5%;
			top: 59.5%;
			width: 16%;
		}

		a#ma25 {
			left: 45.5%;
			top: 58.5%;
			width: 13%;
		}

		a#ma26 {
			left: 64.5%;
			top: 52%;
			width: 15%;
		}

		a#ma27 {
			left: 55.5%;
			top: 62%;
			width: 15%;
		}

		/* a#ma35 { left: 15.5%; top: 62%; width: 15%;} Mellenthin fehlt auf Karte */
		/* a#ma36 { left: 55.5%; top: 62%; width: 15%;} Neu Sallenthin fehlt auf Karte */
		a#ma37 {
			left: 67.5%;
			top: 55.5%;
			width: 22%;
		}

		a#ma38 {
			left: 14.5%;
			top: 12%;
			width: 21%;
		}

		a#ma39 {
			left: 43.5%;
			top: 31%;
			width: 18%;
		}

		a#ma40 {
			left: 46.5%;
			top: 35%;
			width: 20%;
		}

		/* a#ma41 { left: 86.5%; top: 35%; width: 20%;} Korswandt fehlt */
		a#ma42 {
			left: 22.5%;
			top: 40.5%;
			width: 15%;
		}

		a#ma43 {
			left: 40.5%;
			top: 79%;
			width: 16%;
		}

		/* a#ma44 { left: 10.5%; top: 19%; width: 16%;} Stubbenfeld fehlt */
		a#ma45 {
			left: 44.5%;
			top: 38.5%;
			width: 16%;
		}

		a#ma46 {
			left: 20.5%;
			top: 20.5%;
			width: 24%;
		}

		a#ma47 {
			left: 51.5%;
			top: 43%;
			width: 18%;
		}

		a#ma48 {
			left: 37.5%;
			top: 27%;
			width: 18%;
		}

		a#ma49 {
			left: 27.5%;
			top: 24%;
			width: 20%;
			height: 3%;
		}

		#imgmap {
			overflow: hidden;
		}
	}

	.contact-map {
		width: 100%;
		height: 535px;


	}


	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}

		.owl-item img {
			display: inline;
			width: 88px;
		}

		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 20px;
				margin-top: 20px;
				color: var(--color-primary);
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
				color: var(--color-primary);
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}
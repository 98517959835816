.searchpanel-v1 {

	max-width: 290px;



	.guest-people-rows {
		.fa-user:before {
			content: "\f007";
			font-family: "Font Awesome 6 Sharp";
			font-weight: 300;
		}

	}

	.sidebar-search {
		background-color: var(--color-white);


		@media (max-width: 767px) {
			border: 0px solid var(--color-grey-normal);
			padding-bottom: 60px;
			overflow: hidden;
		}
	}

	.guestbox {
		width: 100%;

		.btn {
			width: 20px;
			height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: var(--color-white);
			background-color: var(--color-primary);
			border: 1px solid var(--color-primary);
			min-height: inherit;

			&:hover {
				color: var(--color-white) !important;
				background-color: var(--color-primary) !important;
			}

			.icon {
				font-size: 13px !important;
			}

			.btn-minus {}

			.btn-plus {}
		}
	}


	.btn-go,
	.btn {

		align-items: center;
		display: flex;
		justify-content: center;
		min-height: calc(1.5em + .5rem + 2px);
		padding: .25rem .5rem;

		@media (min-width:1200px) {
			height: 50px;
		}
	}

	.form-group {
		&.alle-orte-dropdown {
			.box {
				&.show {
					.body {
						.list-group {
							@media (min-width: 768px) {
								display: flex;
								flex-wrap: wrap;
								min-width: 500px;
								flex-direction: row;
							}

							.list-group-item {
								@media (min-width: 768px) {
									width: 50%;
								}

								&:nth-child(2) {
									@media (min-width: 768px) {
										border: 1px solid rgba(0, 0, 0, 0.125);
										border-radius: 5px 5px 0 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.search-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.icon-toggle-arrow {
			width: 100%;
			position: relative;
			text-decoration: none;

			.fa-sharp {
				position: absolute;
				right: 0px;
				top: 12px;
			}
		}

		.title {
			font-size: var(--h3-font-size);
			font-weight: 700;
			color: var(--color-secondary);
			font-family: var(--h2-font-family);
		}

	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;
			font-size: var(--font-size-main);

			.checkbox {
				padding: 0 0 0 5px;
			}
		}
	}

	.search-bar-content {

		&.properties-li {
			display: none;

			&.show {
				display: block;
			}
		}

		.filter-icon {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fa,
			.fa-sharp {
				position: absolute;
				top: 20px;
				left: 10px;
				font-size: 1rem;
				pointer-events: none;

				@media (max-width:1199px) {
					top: 10px;
				}
			}
		}

	}

	.voucher {
		margin-bottom: 15px;
		margin-top: 15px;

		.search-bar {
			margin-bottom: 10px;
		}



		.voucher-close {
			float: right;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: .8;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: .5;
			border: none;
			background: transparent;
		}

		.form-control {
			padding: inherit;

			background-color: var(--color-grey-normal);
			border-color: var(--color-grey-normal);

			border: none !important;
			min-height: calc(1.5em + .5rem + 2px);
			padding: .25rem .5rem;

			@media (min-width:1200px) {
				min-height: 48px;
				padding: 14px 1rem !important;
			}
		}
	}



	.sidebar-search {
		@media (min-width:768px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:768px) {
				display: none;
			}



			@media (max-width:767px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}

	#searchpanel {
		.container {
			width: auto;
		}

		@media (min-width:768px) {
			position: relative;
			display: block !important;
			overflow: visible;

			.close {
				display: none;
			}

			&.modal {
				z-index: 3 !important;
			}

			&.fade {
				opacity: 1;
			}

			.modal-dialog {
				width: auto;
				-webkit-transform: translateY(0%);
				transform: translateY(0%);
				margin: 0;
				pointer-events: inherit;
			}
		}


		@media (max-width:767px) {
			.search-bar {
				border: 1px solid #eee;
				border-top: none;

			}

			&.modal.fade {
				.modal-dialog {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
					position: fixed !important;
					background-color: #fff;
				}

				&.show {
					.modal-dialog {
						-webkit-transform: translateX(0);
						transform: translateX(0);
						pointer-events: inherit;
					}
				}
			}
		}

		.modal-dialog {
			@media (max-width:767px) {
				margin: 0;
				padding: 50px 15px 15px;
				z-index: 20001;
				max-width: 650px;
				width: 100%;
				background-color: var(--color-bg-light);
				overflow-y: scroll;
				height: 100vh;

				>.close {
					padding: 0 8px 1px;
					position: absolute;
					top: 6px;
					right: 16px;
					z-index: 50;
					opacity: 1;
					font-size: 36px;
				}
			}
		}
	}

	.form-group {
		margin-bottom: 1rem;
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;


		.fa {
			position: absolute;
			right: 1rem;
			bottom: 18px;
			color: var(--color-grey-dark);
			font-size: 22px;
			display: block;
			line-height: 21px;

			@media (max-width: 992px) {
				font-size: 16px;
				bottom: 13px;
				right: 10px;
			}
		}

	}

	input.form-control,
	button.form-control {
		background-color: var(--color-grey-normal);
		border-color: var(--color-grey-normal);
		padding-left: 45px !important;
	}

	.select-field {
		select.form-control {
			border: none;
			border-bottom: 1px solid #e8e6e1;
			padding-right: 30px !important;
			padding-left: 10px !important;
		}

		i.fa-chevron-down {
			left: inherit !important;
			top: 12px !important;
			right: 0;
			background-color: var(--color-grey-normal);
			padding: 4px 3px;
			border-radius: 3px;
			font-size: 12px !important;
			color: var(--color-primary);
		}
	}



	.form-control {
		border-radius: 3px;
		font-size: var(--font-size-main);
		cursor: pointer;
		height: auto;
		text-align: left;


		@media (min-width:1200px) {
			font-size: var(--font-size-main);
			padding: 14px 1rem;
			min-height: 50px;
		}

		@media (max-width:992px) {
			font-size: var(--font-size-main);
			height: auto;

		}


		&.btn-lg {

			font-size: 16px;
			text-align: left;

			@media (min-width:1200px) {
				font-size: 16px;
				padding: 14px 1rem;
				min-height: 55px;
			}

			/*	@media (max-width:1199px) {
				font-size: 13px;
				height: 55px;
			} */

			@media (max-width:992px) {
				font-size: 16px;
				height: auto;
			}

		}

	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}

}
.static-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.service {
			background-image: url(RESOURCE/img/inner-banner-service.webp);
		}

		&.fur-eigentumer {
			background-image: url(RESOURCE/img/inner-banner-eigentumer.webp);
		}

		&.sport {
			background-image: url(RESOURCE/img/inner-banner-sport.webp);
		}

		&.events {
			background-image: url(RESOURCE/img/inner-banner-events.webp);
		}

		&.sehenswertes {
			background-image: url(RESOURCE/img/inner-banner-sehenswertes.webp);
		}

		&.golfurlaub {
			background-image: url(RESOURCE/img/inner-banner-golfurlaub.webp);
		}

		&.freizeitangebote {
			background-image: url(RESOURCE/img/inner-banner-freizeitangebote.webp);
		}

		&.bestpreisgarantie {
			background-image: url(RESOURCE/img/inner-banner-bestpreisgarantie.webp);
		}

	}

	.iframe-bl {
		iframe {
			height: 1036px;
			display: block;
			min-height: 60px;
			overflow: hidden;
			transition: height 200ms ease-out 0s;
			width: 1px;
			min-width: 100%;

			@media(max-width:992px) {
				height: 2360px;
			}
		}
	}

	.image-bl {
		img {
			border-radius: var(--border-radius);
		}
	}

	.list-check {
		li {
			&:before {
				content: "";
				display: inline-block;
				color: #28a745;
				font-size: 14px;
				position: relative;
				left: 0px;
				font-family: Font Awesome\ 6 Sharp;
				font-weight: 900;
				padding-right: 5px;
			}
		}
	}
}
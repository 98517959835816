.footer-v1 {
	.footer-top {
		padding: 30px 0;
		background-color: #f5f2e7;
		text-align: center;


		h2 {
			font-size: 36px;
			line-height: 44px;
			color: #004169;
			margin-bottom: 25px;
		}

		ul {
			display: flex;
			justify-content: center;
			position: relative;

			&:after {
				content: "";
				padding-bottom: 20px;
				background-image: url(RESOURCE/img/social-underline.svg);
				background-repeat: no-repeat;
				background-position: bottom center;
				background-size: 100% auto;
				width: 72px;
				position: absolute;
				top: 20px;
			}

			li {
				a {
					text-decoration: none;
					color: #004169;
					font-size: 20px;
					padding: 0 8px;
					transition: color 0.3s ease-in-out;
				}
			}
		}
	}

	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		padding: 60px 0;

		.col-md-3 {
			text-align: center;
			border-right: solid 1px #F4F1EC;

			&:last-child {
				border-right: none;
			}

			.ft-content {
				text-align: left;
				display: inline-block;

				@media (max-width:992px) {
					padding-top: 30px;
					padding-bottom: 30px;
					border-bottom: solid 1px #F4F1EC;
					text-align: center;
					display: block;
				}

				li {
					margin-bottom: 10px;
				}
			}
		}



		.text-muted {
			color: var(--color-grey-normal) !important;
		}



		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-primayr);

			font-family: var(--title-font-family-main);
			font-weight: bold;
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.v-office-logo {

			background-color: var(--color-secondary);
			padding: 10px 20px;

			@media(min-width: 993px) {
				text-align: right;
				padding: 10px 60px;
			}

			@media(max-width: 992px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);

			&:hover {
				color: #CBBAA1;
				text-decoration: none;
			}
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa,
				.fa-brands {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.input-group {
			.input-bl {
				letter-spacing: -1px;
				padding: 0 0px;
			}
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}
	}


}
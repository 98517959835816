.unit-image-gallery-v3 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;
	position: relative;

	.visible-print {
		display: none !important;
	}


	.unitGallery {
		a {
			display: none;
			padding: 5px;
			height: 200px;

			&:nth-child(2),
			&:nth-child(3) {
				height: 200px;
			}

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: var(--border-radius);
			}

			&:nth-child(1) {
				width: 50%;
				float: left;
				display: block;
				height: 400px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}
			}

			&:nth-child(2),
			&:nth-child(3) {
				width: 25%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 200px;
				}
			}

			&:nth-child(4) {


				@media (max-width: 767px) {
					clear: none;
					height: 140px;
				}
			}

			&:nth-child(4),
			&:nth-child(5) {
				display: block;
				width: 25%;
				float: left;


				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(7n-1) {
				display: none !important;
			}

			&:nth-child(5) {
				position: relative;

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;
					border-radius: var(--border-radius);

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

				.img-count {
					position: absolute;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

			}
		}
	}

}